/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string, shape, number } from 'prop-types';
import loadable from '@loadable/component';
import mbpLogger from 'mbp-logger';

import { useSelector, useDispatch } from 'react-redux';
import useSalesforceProductRecommendationQuery from '../../../../helpers/Personalization/useSalesforceProductRecommendationQuery';
import useClickStreamCustomEventsTracking from '../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import ComponentErrorBoundary from '../../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const LoadableSimpleCarouselBuilder = loadable(() => import(/* webpackChunkName: "SimpleCarouselBuilder" */ '../../EnterpriseID/Common/SimpleCarouselBuilder'));

const TrendingProductsABContainer = ({
    blockData,
}) => {
    const dispatch = useDispatch();

    const {
        title: cmsTitle,
        css_classes,
        title_font_color,
        title_font_family,
        title_font_size,
        title_font_style,
        title_margin,
        align_title,
        products_to_show,
        padding,
        tracking_event_category,
        tracking_event_action,
        tracking_event_label,
        campaign_id,
    } = blockData || {};

    const {
        loading, error, data, variables,
    } = useSalesforceProductRecommendationQuery({
        queryName: 'TrendingProducts',
        campaignFeature: campaign_id || 'Trending',
        targeting: {
            pageType: 'home',
        },
        optInHoldoutParticipation: true,
        resolveIdentity: true,
        filterByZipCode: true,
    });

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: data?.productRecommendationsSFMulti?.[0],
        isFireImpression: true,
        page: { type: 'home' },
    });

    if (loading || variables?.skip) {
        return null;
    }

    if (error) {
        mbpLogger.logError({
            function: 'TrendingProductsABContainer.js',
            message: 'Error loading data from saleforce api',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
    }

    const productClickCallback = (partNumber) => {
        trackClickThrough(partNumber);
        // call GA Events
        dispatch(trackEvent({
            eventCategory: tracking_event_category || 'Homepage',
            eventAction: tracking_event_action || 'Trending Gifts',
            eventLabel: tracking_event_label || 'Trending Gifts - Click',
        }));
    };

    if (data?.productRecommendationsSFMulti?.[0]?.products?.length > 0) {
        const title = data?.productRecommendationsSFMulti?.[0]?.campaign?.campaignResponses?.[0]?.payload?.productRecTitle;
        const carouselData = data?.productRecommendationsSFMulti?.[0]?.content?.personalized_carousel;
        const productsToShow = {
            desktop: carouselData?.products_to_show?.desktop || products_to_show?.desktop,
            tablet: carouselData?.products_to_show?.tablet || products_to_show?.tablet,
            mobile: carouselData?.products_to_show?.mobile || products_to_show?.mobile,
        };

        return (
            <ComponentErrorBoundary function="TrendingProductsABContainer" module="PersonalizedContent">
                <LoadableSimpleCarouselBuilder
                    blockData={{
                        productsToShow,
                        title: title || carouselData?.heading[0]?.copy || cmsTitle || 'Trending Products For You',
                        css_classes,
                        titleFontColor: carouselData?.heading[0]?.color?.color || title_font_color,
                        titleFontSize: carouselData?.heading[0]?.font_size || title_font_size,
                        titleFontStyle: carouselData?.heading[0]?.font_style || title_font_style,
                        titleFontFamily: carouselData?.heading[0]?.font_family || title_font_family,
                        titleFontWeight: carouselData?.heading[0]?.font_weight,
                        titleMargin: carouselData?.heading[0]?.margin || title_margin,
                        alignTitle: carouselData?.heading[0]?.align_title || align_title || '',
                        padding: carouselData?.padding || padding,
                    }}
                    products={data.productRecommendationsSFMulti?.[0]?.products || []}
                    productClickCallback={productClickCallback}
                    demandType="XS"
                />
            </ComponentErrorBoundary>
        );
    }

    return null;
};

TrendingProductsABContainer.propTypes = {
    blockData: shape({
        title: string,
        title_font_color: shape({
            color: string.isRequired,
        }),
        title_font_size: string,
        title_font_style: string,
        title_font_family: string,
        align_title: string,
        padding: string,
        products_to_show: shape({
            desktop: number,
            tablet: number,
            mobile: number,
        }),
        campaign_id: string,
    }).isRequired,
};

export default TrendingProductsABContainer;
