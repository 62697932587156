/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string, shape } from 'prop-types';
import mbpLogger from 'mbp-logger';
import { useDispatch } from 'react-redux';

import ComponentErrorBoundary from '../../../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';
import CarouselBuilderSkeleton from '../../../GraphqlSkeletonComponents/CarouselBuilderSkeleton';
import PersonalizedHPCarouselBuilder from '../PersonalizedHomePageCarouselBuilder/PersonalizedHomePageCarouselBuilder';
import useSalesforceRecentlyViewedRecsQuery from '../../../../../helpers/Personalization/useSalesforceRecentlyViewedRecsQuery';
import useSalesforceBuyItAgainRecsQuery from '../../../../../helpers/Personalization/useSalesforceBuyItAgainRecsQuery';
import useClickStreamCustomEventsTracking from '../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import { emitProductImpression, emitCustomTrackingEvent } from '../../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import PersonalizedHPGridUI from '../PersonalizedHomePageGridUI/PersonalizedHomePageGridUI';

const PersonalizedHomePageSFContainer = ({ blockObj, customerRecsFallbackTitle = 'Recently Viewed Products', recommendationsType = 'recentlyViewedRecs' }) => {
    const dispatch = useDispatch();

    const {
        products_to_show,
        pz_campaign_id: recentlyViewedCampaignFeature = '',
        pz_buy_again_campaign_id: buyAgainCampaignFeature = '',
    } = blockObj || {};

    const isRecentlyViewedRecs = recommendationsType === 'recentlyViewedRecs';
    const useSalesforceDynamicQuery = isRecentlyViewedRecs ? useSalesforceRecentlyViewedRecsQuery : useSalesforceBuyItAgainRecsQuery;

    const {
        loading,
        error,
        data,
        variables,
    } = useSalesforceDynamicQuery({
        queryName: isRecentlyViewedRecs ? 'RecentlyViewedRecommendations' : 'BuyItAgainRecommendations',
        campaignFeature: isRecentlyViewedRecs ? recentlyViewedCampaignFeature : buyAgainCampaignFeature,
        targeting: {
            pageType: 'home',
        },
        filterByZipCode: true,
        optInHoldoutParticipation: true,
        resolveIdentity: true,
    });

    const dynamicSalesforceData = data?.[`${recommendationsType}`];

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: dynamicSalesforceData,
        isFireImpression: dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.experienceName === 'Control',
        page: { type: 'home' },
    });

    if (loading || variables?.skip) {
        return (
            <CarouselBuilderSkeleton
                nDisplayProducts={products_to_show}
                breakPoints={{ mobile: 601, desktop: 769 }}
            />
        );
    }

    if (error) {
        mbpLogger.logError({
            function: 'PersonalizedHomePageSFContainer.js',
            message: 'Error loading data from Salesforce API',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return null;
    }

    const renderCarousel = (carouselTitle, products, productStyleConfig) => (
        <ComponentErrorBoundary function="PersonalizedHomePageSFContainer" module="PersonalizedContent" message="Something went wrong when displaying Personalized HP Recommendations from Salesforce">
            <PersonalizedHPCarouselBuilder
                blockData={{
                    productsToShow: products_to_show,
                    title: carouselTitle,
                    titleMargin: productStyleConfig?.title_styling?.title_margin,
                    titleFontColor: productStyleConfig?.title_styling?.title_font_color?.color,
                    titleFontSize: productStyleConfig?.title_styling?.title_font_size,
                    titleFontStyle: productStyleConfig?.title_styling?.title_font_style,
                    titleFontFamily: productStyleConfig?.title_styling?.title_font_family,
                    alignTitle: productStyleConfig?.title_styling?.align_title,
                    padding: productStyleConfig?.title_styling?.padding,
                }}
                productClickCallback={trackClickThrough}
                products={products}
                demandType="XS"
                productStyleConfig={productStyleConfig}
            />
        </ComponentErrorBoundary>
    );

    if (dynamicSalesforceData?.products || dynamicSalesforceData?.productRecs) {
        const titleSF = dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.productRecTitle || data?.recentlyViewedRecs?.campaign?.campaignResponses?.[0]?.payload?.fallbackProductRecTitle || dynamicSalesforceData?.campaign?.campaignResponses[0]?.payload?.buyAgainRecTitle;
        const subTitle = dynamicSalesforceData?.campaign?.campaignResponses[0]?.payload?.productRecSubTitle || dynamicSalesforceData?.campaign?.campaignResponses[0]?.payload?.buyAgainRecSubTitle;
        const displayType = dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.displayType || '';
        const isGridView = dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.layoutDisplayType === 'GRID';
        const minimumProductsToShow = dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.minimumProductsToShow || 2;
        const products = dynamicSalesforceData?.products;
        const productRecs = dynamicSalesforceData?.productRecs;
        const productStylingConfig = dynamicSalesforceData?.productsContent || {};
        const productRecStylingConfig = dynamicSalesforceData?.productRecsContent || {};
        const {
            main_title, subtitle, show_subtitle, title_styling,
        } = productStylingConfig;

        const trackImpression = () => dispatch(emitCustomTrackingEvent({
            action: 'Impression',
            salesforceResponse: dynamicSalesforceData,
            page: { type: 'home' },
            eventSubType: 'campaign-tracking',
            displayType,
            minimumProductsToShow,
        }));

        switch (displayType) {
            case 'BOTH':
                trackImpression();
                if (products?.length >= minimumProductsToShow) {
                    dispatch(emitProductImpression({
                        action: 'Impression',
                        productsPayload: {
                            products,
                            mobileBreakpoint: 600,
                        },
                        salesforceResponse: dynamicSalesforceData,
                        page: { type: 'home' },
                    }));
                }

                if (isGridView) {
                    return (
                        <ComponentErrorBoundary function="PersonalizedHPGridUI" module="PersonalizedContent" message="Something went wrong when displaying Personalized HP Recommendations from Salesforce">
                            <PersonalizedHPGridUI
                                sfData={data}
                                cmsData={{
                                    ...title_styling, title: titleSF, subTitle: show_subtitle ? subtitle : '', min_products_to_show: minimumProductsToShow,
                                }}
                                isNewDesign={false}
                                productClickCallback={trackClickThrough}
                                productStyleConfig={productStylingConfig}
                                productRecStyleConfig={productRecStylingConfig}
                                recommendationsType={recommendationsType}
                            />
                        </ComponentErrorBoundary>
                    );
                }
                return (
                    <>
                        {renderCarousel(main_title || customerRecsFallbackTitle, products?.length >= minimumProductsToShow ? products : [], productStylingConfig)}
                        {renderCarousel(titleSF || productRecStylingConfig?.main_title, productRecs, productRecStylingConfig)}
                    </>
                );

            case 'CUST_PRODUCTS_ONLY':
                trackImpression();
                if (products?.length >= minimumProductsToShow) {
                    dispatch(emitProductImpression({
                        action: 'Impression',
                        productsPayload: {
                            products,
                            mobileBreakpoint: 600,
                        },
                        salesforceResponse: dynamicSalesforceData,
                        page: { type: 'home' },
                    }));
                }

                if (isGridView) {
                    return (
                        <ComponentErrorBoundary function="PersonalizedHPGridUI" module="PersonalizedContent" message="Something went wrong when displaying Personalized HP Recommendations from Salesforce">
                            <PersonalizedHPGridUI
                                sfData={data}
                                cmsData={{
                                    ...title_styling, title: main_title, subTitle: subtitle, min_products_to_show: minimumProductsToShow,
                                }}
                                isNewDesign={false}
                                productClickCallback={trackClickThrough}
                                productStyleConfig={productStylingConfig}
                                productRecStyleConfig={null}
                                recommendationsType={recommendationsType}
                            />
                        </ComponentErrorBoundary>
                    );
                }
                return renderCarousel(main_title || customerRecsFallbackTitle, products?.length >= minimumProductsToShow ? products : [], productStylingConfig);

            case 'PRODUCT_RECS_ONLY':
                trackImpression();

                if (isGridView) {
                    return (
                        <ComponentErrorBoundary function="PersonalizedHPGridUI" module="PersonalizedContent" message="Something went wrong when displaying Personalized HP Recommendations from Salesforce">
                            <PersonalizedHPGridUI
                                sfData={data}
                                cmsData={{
                                    ...title_styling, title: titleSF, subTitle, min_products_to_show: minimumProductsToShow,
                                }}
                                isNewDesign={false}
                                productClickCallback={trackClickThrough}
                                productStyleConfig={null}
                                productRecStyleConfig={productRecStylingConfig}
                                recommendationsType={recommendationsType}
                            />
                        </ComponentErrorBoundary>
                    );
                }
                return renderCarousel(titleSF || productRecStylingConfig?.main_title, productRecs, productRecStylingConfig);

            default:
                return null;
        }
    }
    return null;
};

PersonalizedHomePageSFContainer.propTypes = {
    blockObj: shape({
        title_font_color: shape({
            color: string.isRequired,
        }),
        title_font_size: string,
        title_font_style: string,
        title_font_family: string,
        padding: string,
    }).isRequired,
    customerRecsFallbackTitle: string.isRequired,
    recommendationsType: string.isRequired,
};

export default PersonalizedHomePageSFContainer;
