/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { func, object, string } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import styles from '../../../UniversalTemplate/Components/Banners/OneUp/Standard/Tall/Tall.module.css';
import ComponentErrorBoundary from '../../../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';
import LinkListBanner from '../../../UniversalTemplate/SubComponents/LinkListBanner/LinkListBanner';
import ResponsiveWrapper from '../../../UniversalTemplate/SubComponents/ResponsiveWrapper';
import OfferGatewayMessageStandard from './OfferGatewayMessageStandard';
import useClickStreamCustomEventsTracking from '../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import noop from '../../../../../helpers/noop';
import { getOfferGatewayData } from '../../../../../../state/ducks/App/App-Selectors';
import useOfferGatewayClickHandler from './useOfferGatewayClickHandler';

const useCustomStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
    },
    banner: ({ bannerHeight, bannerWidth }) => ({
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        maxWidth: '1920px',
        height: bannerHeight,
        width: bannerWidth,
        [theme.breakpoints.down(600)]: {
            maxWidth: '-webkit-fill-available',
        },
    }),
}));
export default function OfferGatewayBannerTall({
    blockObj, salesforceResponse, setShowSkeleton, bannerHeight, bannerWidth,
}) {
    useStyles(styles);
    const classes = useCustomStyles({ bannerHeight, bannerWidth });
    const bannerRef = useRef(null);
    const offerGatewayData = useSelector(getOfferGatewayData);

    if (!Object.keys(blockObj || {})?.length) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'OfferGatewayBannerTall.js',
            message: 'No data available to render component',
        });
        return <></>;
    }

    const campaignPayload = salesforceResponse.campaign?.campaignResponses?.[0]?.payload || {};
    const isOfferUnlocked = campaignPayload.offerFlow === 'Offer Unlocked';

    const isBackground = blockObj?.message?.has_background;
    const linkListPosition = blockObj?.link_list?.[0]?.link_list?.position || 'row';
    const backgrounds = {
        desktop: blockObj.background_image?.desktop?.url,
        tablet: blockObj.background_image.tablet?.url || blockObj.background_image?.desktop?.url, // can add fallbacks easily this way
        mobile: blockObj.background_image.mobile?.url || blockObj.background_image.tablet?.url || blockObj.background_image?.desktop?.url,
    };

    useClickStreamCustomEventsTracking({
        salesforceResponse,
        isFireImpression: true,
        page: { type: 'home' },
    });

    useEffect(() => {
        setShowSkeleton(false);
    }, [isOfferUnlocked, offerGatewayData?.offerScrolled]);

    const { handleBannerClick } = useOfferGatewayClickHandler(salesforceResponse);

    const device = (matches) => Object.keys(matches).find((key) => matches[key]); // returns device type string

    const renderLinkList = (matches, deviceArray) => {
        if (blockObj.has_link_list && deviceArray.includes(device(matches))) return <LinkListBanner data={blockObj.link_list[0]} />;
        return <></>;
    };

    const imageQuality = '?auto=webp';

    // UI examples: https://1800flowersinc.atlassian.net/wiki/spaces/CMS/pages/2437283850/Banners#Tall
    // This component is a replica of Banner1UpTallStandard from /UniversalTemplate/Components/Banners/OneUp/Standard/Tall/Tall.js
    // with some minor changes to fit the OfferGateway use case

    const renderBanner = (matches) => (
        <>
            <div
                style={{ backgroundImage: `url('${backgrounds[device(matches)]}${imageQuality}')`, flexDirection: linkListPosition }}
                className={`${classes.banner} ${blockObj?.has_link_list ? styles.wrapperContainer : ''}`}
                onClick={handleBannerClick}
                role="button"
                tabIndex={0}
                aria-label="OfferGateway banner"
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleBannerClick();
                    }
                }}
            >
                {Object.keys(blockObj.message)?.length > 0 && (blockObj?.link_list?.length > 0 && (isBackground || !isBackground)) && <div className={`${styles.messageBlock} ${isBackground ? styles.messageBg : ''}`}><OfferGatewayMessageStandard salesforceResponse={salesforceResponse} data={blockObj.message} /></div>}
                {!isBackground && !blockObj?.link_list?.length && <div className={`${styles.messageBlock} ${isBackground ? styles.messageBg : ''}`}><OfferGatewayMessageStandard salesforceResponse={salesforceResponse} data={blockObj.message} /></div>}
                {(device(matches) === 'desktop' || device(matches) === 'tablet') && isBackground && !blockObj?.link_list?.length && <div className={`${styles.messageBlock} ${isBackground ? styles.messageBg : ''}`}><OfferGatewayMessageStandard salesforceResponse={salesforceResponse} data={blockObj.message} /></div>}

                {renderLinkList(matches, ['desktop'])}
            </div>
            {device(matches) === 'mobile' && isBackground && !blockObj?.link_list?.length && <div className={`${styles.messageBlock} ${isBackground ? styles.messageBg : ''}`}><OfferGatewayMessageStandard salesforceResponse={salesforceResponse} data={blockObj.message} /></div>}

            {renderLinkList(matches, ['tablet', 'mobile'])}

            {backgrounds[device(matches)] && (
                <picture>
                    <source srcSet={`${backgrounds[device(matches)]}${imageQuality}`} />
                    <img src={`${backgrounds[device(matches)]}${imageQuality}`} alt="background image" height="0" width="0" className={styles.lcpImage} loading="lazy" />
                </picture>
            )}
        </>
    );
    const bannerLink = blockObj.banner_click_url;

    return (
        <ComponentErrorBoundary module="OfferGateway.BannerTall">
            <div data-testid="tallBanner" ref={bannerRef} className={`${bannerLink?.href && bannerLink?.title ? styles.bannerContainer : ''} ${isBackground && blockObj?.has_link_list ? styles.container : ''}`} role="presentation">
                {bannerLink?.href
                    ? (
                        <Link to={bannerLink?.href} className={classes.link}>
                            <ResponsiveWrapper renderer={renderBanner} />
                        </Link>
                    )
                    : <ResponsiveWrapper renderer={renderBanner} />}
            </div>
        </ComponentErrorBoundary>
    );
}

OfferGatewayBannerTall.propTypes = {
    blockObj: object.isRequired,
    salesforceResponse: object.isRequired,
    setShowSkeleton: func,
    bannerHeight: string,
    bannerWidth: string,
};

OfferGatewayBannerTall.defaultProps = {
    setShowSkeleton: noop,
    bannerHeight: '',
    bannerWidth: '',
};
