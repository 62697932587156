/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string, shape } from 'prop-types';
import mbpLogger from 'mbp-logger';
import { useDispatch } from 'react-redux';

import ComponentErrorBoundary from '../Common/ComponentErrorBoundary/ComponentErrorBoundary';
import CarouselBuilderSkeleton from '../../GraphqlSkeletonComponents/CarouselBuilderSkeleton';
import SimpleCarouselBuilder from '../Common/SimpleCarouselBuilder';
import useSalesforceRecentlyViewedRecsQuery from '../../../../helpers/Personalization/useSalesforceRecentlyViewedRecsQuery';
import useClickStreamCustomEventsTracking from '../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import { emitProductImpression, emitCustomTrackingEvent } from '../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import RecentlyViewedGridUI from './RecentlyViewedGridUI/RecentlyViewedGridUI';

const RecentlyViewedRecsSFContainer = ({ blockObj }) => {
    const dispatch = useDispatch();

    const {
        products_to_show,
        min_products_to_show,
        title,
        css_classes,
        title_margin,
        title_font_color,
        title_font_size,
        title_font_family,
        title_font_style,
        pz_campaign_id: campaignFeature = '',
        align_title = 'center',
        padding,
    } = blockObj || {};

    const {
        loading,
        error,
        data,
        variables,
    } = useSalesforceRecentlyViewedRecsQuery({
        queryName: 'RecentlyViewedRecommendations',
        campaignFeature,
        targeting: {
            pageType: 'home',
        },
        filterByZipCode: true,
        optInHoldoutParticipation: true,
        resolveIdentity: true,
    });

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: data?.recentlyViewedRecs,
        isFireImpression: data?.recentlyViewedRecs?.campaign?.campaignResponses?.[0]?.experienceName === 'Control',
        page: { type: 'home' },
    });

    if (loading || variables?.skip) {
        return (
            <CarouselBuilderSkeleton
                nDisplayProducts={products_to_show}
                breakPoints={{ mobile: 601, desktop: 769 }}
            />
        );
    }

    if (error) {
        mbpLogger.logError({
            function: 'RecentlyViewedRecsSFContainer.js',
            message: 'Error loading data from Salesforce API',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return null;
    }

    const renderCarousel = (carouselTitle, products) => (
        <ComponentErrorBoundary function="RecentlyViewedRecsSFContainer" module="PersonalizedContent" message="Something went wrong when displaying Recently Viewed Recommendations from Salesforce">
            <SimpleCarouselBuilder
                blockData={{
                    productsToShow: products_to_show,
                    title: carouselTitle,
                    css_classes,
                    titleMargin: title_margin,
                    titleFontColor: title_font_color?.color,
                    titleFontSize: title_font_size,
                    titleFontStyle: title_font_style,
                    titleFontFamily: title_font_family,
                    alignTitle: align_title,
                    padding,
                }}
                productClickCallback={trackClickThrough}
                products={products}
                demandType="XS"
            />
        </ComponentErrorBoundary>
    );

    if (data?.recentlyViewedRecs?.products || data?.recentlyViewedRecs?.productRecs) {
        const titleSF = data?.recentlyViewedRecs?.campaign?.campaignResponses?.[0]?.payload?.productRecTitle || data?.recentlyViewedRecs?.campaign?.campaignResponses?.[0]?.payload?.fallbackProductRecTitle;
        const displayType = data?.recentlyViewedRecs?.campaign?.campaignResponses?.[0]?.payload?.displayType || '';
        const products = data?.recentlyViewedRecs?.products;
        const productRecs = data?.recentlyViewedRecs?.productRecs;
        const isGridView = data?.recentlyViewedRecs?.campaign?.campaignResponses?.[0]?.experienceName === 'GRID';
        const isNewDesign = data?.recentlyViewedRecs?.campaign?.campaignResponses?.[0]?.experienceName === 'New Design';

        const trackImpression = () => dispatch(emitCustomTrackingEvent({
            action: 'Impression',
            salesforceResponse: data?.recentlyViewedRecs,
            page: { type: 'home' },
            eventSubType: 'campaign-tracking',
            displayType,
            min_products_to_show,

        }));

        switch (displayType) {
            case 'BOTH':
                trackImpression();
                if (products?.length >= min_products_to_show) {
                    dispatch(emitProductImpression({
                        action: 'Impression',
                        productsPayload: {
                            products,
                            mobileBreakpoint: 600,
                        },
                        salesforceResponse: data?.recentlyViewedRecs,
                        page: { type: 'home' },
                    }));
                }

                if (isGridView || isNewDesign) {
                    return (
                        <ComponentErrorBoundary function="RecentlyViewedGridUI" module="PersonalizedContent" message="Something went wrong when displaying Recently Viewed Recommendations from Salesforce">
                            <RecentlyViewedGridUI sfData={data} cmsData={blockObj} isNewDesign={isNewDesign} />
                        </ComponentErrorBoundary>
                    );
                }
                return (
                    <>
                        {renderCarousel(title || 'Recently Viewed Products', products?.length >= min_products_to_show ? products : [])}
                        {renderCarousel(titleSF, productRecs)}
                    </>
                );

            case 'CUST_PRODUCTS_ONLY':
                trackImpression();
                if (products?.length >= min_products_to_show) {
                    dispatch(emitProductImpression({
                        action: 'Impression',
                        productsPayload: {
                            products,
                            mobileBreakpoint: 600,
                        },
                        salesforceResponse: data?.recentlyViewedRecs,
                        page: { type: 'home' },
                    }));
                }

                if (isGridView || isNewDesign) {
                    return (
                        <ComponentErrorBoundary function="RecentlyViewedGridUI" module="PersonalizedContent" message="Something went wrong when displaying Recently Viewed Recommendations from Salesforce">
                            <RecentlyViewedGridUI sfData={data} cmsData={blockObj} isNewDesign={isNewDesign} />
                        </ComponentErrorBoundary>
                    );
                }
                return renderCarousel(title || 'Recently Viewed Products', products?.length >= min_products_to_show ? products : []);

            case 'PRODUCT_RECS_ONLY':
                trackImpression();

                if (isGridView || isNewDesign) {
                    return (
                        <ComponentErrorBoundary function="RecentlyViewedGridUI" module="PersonalizedContent" message="Something went wrong when displaying Recently Viewed Recommendations from Salesforce">
                            <RecentlyViewedGridUI sfData={data} cmsData={blockObj} isNewDesign={isNewDesign} />
                        </ComponentErrorBoundary>
                    );
                }
                return renderCarousel(titleSF, productRecs);

            default:
                return null;
        }
    }
    return null;
};

RecentlyViewedRecsSFContainer.propTypes = {
    blockObj: shape({
        title_font_color: shape({
            color: string.isRequired,
        }),
        title_font_size: string,
        title_font_style: string,
        title_font_family: string,
        padding: string,
    }).isRequired,
};

export default RecentlyViewedRecsSFContainer;
