/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { useQuery } from '@apollo/client';
import mbpLogger from 'mbp-logger';
import { useSelector } from 'react-redux';
import { bool, object, string } from 'prop-types';

import GenericSkeleton from '../../GraphqlSkeletonComponents/GenericSkeleton';
// eslint-disable-next-line import/no-cycle
import HomePageBlock from '../../GraphqlHomePage/HomePageBlock';
import SiteWideBannerContainer from '../../GraphqlHeader/SiteWideBanner/SiteWideBannerContainer';
import findContentByUID from '../../../../gql/queries/findContentByUID';
import { getBrandName } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getSSRDeviceType } from '../../../../../state/ducks/App/App-Selectors';
import { getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';

export default function FallbackContentContainer({
    fallbackUID,
    contentType,
    skeleton_height,
    skeleton_width,
    hasTopMargin,
    salesforceResponse,
}) {
    const brand = useSelector(getBrandName) || '1800flowers';
    const ssrDeviceType = useSelector(getSSRDeviceType);
    const presentationFamily = useSelector(getPresentationFamily);

    const CONTENT_QUERY = findContentByUID({
        brand,
        uid: fallbackUID,
        contentType,
    });

    const {
        loading, data, error,
    } = useQuery(CONTENT_QUERY, {
        skip: !fallbackUID,
    });

    if (loading) {
        return (
            <GenericSkeleton
                height={skeleton_height}
                width={skeleton_width}
                id={`fallback-${contentType}`}
                maxWidth="unset"
                margin="0 auto"
            />
        );
    }

    if (error) {
        mbpLogger.logError({
            function: 'FallbackContentContainer',
            message: 'Error loading API data',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return null;
    }

    if (data?.findContentByUID?.content) {
        let block = {
            [contentType]: data.findContentByUID.content.entry,
        };
        if (contentType === 'generic_banner') {
            // In generic banner component useUIDQueryConditionally is resolves all inner references
            block = {
                [contentType]: {
                    reference: [{ uid: fallbackUID, _content_type_uid: contentType }],
                },
            };
        }

        if (contentType === 'sitewide_banner_v2') {
            block = data?.findContentByUID?.content?.entry;
            return <SiteWideBannerContainer bannerData={block} />;
        }

        return (
            <HomePageBlock
                block={block}
                ssrDeviceType={ssrDeviceType}
                presentationFamily={presentationFamily}
                brand={brand}
                hasTopMargin={hasTopMargin}
                isFromPersonalizedContentBlock
                salesforceResponse={salesforceResponse}
            />
        );
    }

    return null;
}

FallbackContentContainer.propTypes = {
    fallbackUID: string,
    contentType: string,
    skeleton_height: string,
    skeleton_width: string,
    hasTopMargin: bool,
    salesforceResponse: object,
};

FallbackContentContainer.defaultProps = {
    fallbackUID: '',
    contentType: '',
    skeleton_height: '',
    skeleton_width: '',
    hasTopMargin: false,
    salesforceResponse: {},
};
