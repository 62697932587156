/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { bool, object } from 'prop-types';
import { useSelector } from 'react-redux';

import Heading from './Heading';
import MobileUIContainer from './MobileUIContainer';
import DesktopUIContainer from './DesktopUIContainer';
import useClickStreamCustomEventsTracking from '../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getEnterpriseId } from '../../../../../../state/ducks/Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import { getIsBot } from '../../../../../../state/ducks/App/App-Selectors';
import { getIsAuthenticatedStatus, getIsPassportUserLoggedIn } from '../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getPassportSubscriptionStatus } from '../../../../../../state/ducks/Common/Common-Selectors';
import validatePassport from '../../../../../helpers/validatePassport';
import { getPassportData } from '../../../../../../state/ducks/Passport/Passport-Selectors';
import DynamicPrice from '../../../GraphqlProductPage/Partials/GraphqlPDPSkus/DynamicPrice';

const useStyles = makeStyles((theme) => ({
    main: (styles) => ({
        width: '100%',
        maxWidth: '1400px',
        margin: '0 auto',
        padding: '32px 0px',
        [theme.breakpoints.down(600)]: {
            padding: '24px 0px 32px',
            marginBottom: '32px',
        },
    }),
    root: {
        maxWidth: '1440px',
        margin: '0 auto',
        display: 'flex',
        gap: '16px',
        [theme.breakpoints.down(600)]: {
            padding: '0 16px',
        },
    },
    recentlyViewedSectionBGColor: (styles) => ({
        backgroundColor: styles?.backgroundColor,
    }),
    buyItAgainSectionBGColor: (styles) => ({
        backgroundColor: styles?.buyItAgainSectionBGColor,
    }),
}));

export default function BuyItAgainRecsGridUI({ sfData, cmsData }) {
    const isMobile = useMediaQuery('(max-width: 600px)');

    const {
        buy_again_title,
        title_font_family: fontFamily,
        title_font_size: fontSize,
        title_font_style: fontStyle,
        title_font_color,
    } = cmsData || {};

    const recentlyViewedSectionBGColor = sfData?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.payload?.productRecBackgroundColor || '#FFF8F0';
    const buyItAgainSectionBGColor = sfData?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.payload?.buyAgainRecBackgroundColor || '#FFF8F0';
    const classes = useStyles({ backgroundColor: recentlyViewedSectionBGColor, buyItAgainSectionBGColor });

    const title = sfData?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.payload?.productRecTitle || sfData?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.payload?.fallbackProductRecTitle;
    const subTitle = sfData?.buyAgainRecs?.campaign?.campaignResponses[0]?.payload?.productRecSubTitle;

    // Buy it again title and subtitle
    const buyItAgainTitle = sfData?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.payload?.buyAgainRecTitle;
    const buyItAgainSubTitle = sfData?.buyAgainRecs?.campaign?.campaignResponses[0]?.payload?.buyAgainRecSubTitle;

    // fetching minimum rows to show, minimum products to show from salesforce template
    const minimumRowsToShow = sfData?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.payload?.minimumRowsToShow;
    const minimumProductsToShow = sfData?.buyAgainRecs?.campaign?.campaignResponses[0]?.payload?.minimumProductsToShow;

    // Buy it again styles
    const buyItAgainHeadingStyle = sfData?.buyAgainRecs?.productsContent?.personalized_carousel?.heading[0] || {};
    const buyItAgainSubHeadingStyle = sfData?.buyAgainRecs?.productsContent?.personalized_carousel?.heading[1] || {};
    const buyAgaincustomStyles = {
        fontFamily: buyItAgainHeadingStyle?.font_family,
        fontSize: buyItAgainHeadingStyle?.font_size,
        fontStyle: buyItAgainHeadingStyle?.font_style,
        color: buyItAgainHeadingStyle?.color?.color,
        fontFamily1: buyItAgainSubHeadingStyle?.font_family,
        fontSize1: buyItAgainSubHeadingStyle?.font_size,
        fontStyle1: buyItAgainSubHeadingStyle?.font_style,
        color1: buyItAgainSubHeadingStyle?.color?.color,
        backgroundColor: buyItAgainSectionBGColor,
    };

    // Recently viewed styles
    const recentlyViewedHeadingStyle = sfData?.buyAgainRecs?.productRecsContent?.personalized_carousel?.heading[0] || {};
    const recentlyViewedSubHeadingStyle = sfData?.buyAgainRecs?.productRecsContent?.personalized_carousel?.heading[1] || {};
    const seedProduct = sfData?.buyAgainRecs?.seedProduct;
    const recentlyViewedcustomStyles = {
        fontFamily: recentlyViewedHeadingStyle?.font_family || fontFamily,
        fontSize: recentlyViewedHeadingStyle?.font_size || fontSize,
        fontStyle: recentlyViewedHeadingStyle?.font_style || fontStyle,
        color: recentlyViewedHeadingStyle?.color?.color || title_font_color?.color,
        fontFamily1: recentlyViewedSubHeadingStyle?.font_family,
        fontSize1: recentlyViewedSubHeadingStyle?.font_size,
        fontStyle1: recentlyViewedSubHeadingStyle?.font_style,
        color1: recentlyViewedSubHeadingStyle?.color?.color,
        backgroundColor: recentlyViewedSectionBGColor,
    };

    const showBuyItAgain = sfData?.buyAgainRecs?.products?.length >= minimumProductsToShow;

    const enterpriseId = useSelector((state) => getEnterpriseId(state));
    const [isDynamicPricesFetched, setIsDynamicPricesFetched] = useState(false);
    const [dynamicPriceIsLoading, setDynamicPriceLoading] = useState(true);
    const [productSkuPrices, setProductSkuPrices] = useState([]);
    const isBot = useSelector((state) => getIsBot(state));
    const isOptimizeScriptEnabled = useSelector((state) => getFeatureFlag('is-optimize-script-enabled')(state));
    const isDynamicPriceEnabled = useSelector((state) => getFeatureFlag('is-dynamic-pricing-enabled')(state));

    // Set customer type ie. Passport, Registered.
    const isPassportUser = useSelector(getIsPassportUserLoggedIn);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const passportData = useSelector(getPassportData);
    const isPassportMember = validatePassport('isActivePassportUser', passportData);
    const isPassportAdded = useSelector(getPassportSubscriptionStatus);
    let customerTypeValue = [];
    if ((isPassportMember && isPassportUser) || isPassportAdded) {
        customerTypeValue = [{
            name: 'CUSTOMER_TYPE',
            value: 'Passport',
        }];
    } else if (isAuthenticated) {
        customerTypeValue = [{
            name: 'CUSTOMER_TYPE',
            value: 'Registered',
        }];
    }

    const productRecs = sfData?.buyAgainRecs?.productRecs;
    const recentProducts = sfData?.buyAgainRecs?.products;

    const dynamicPricingProducts = [...recentProducts, ...productRecs].map((item) => ({ ...item, id: item.partNumber }));

    // Declaring Dynamic price call instance
    const dynamicPriceInstance = new DynamicPrice(dynamicPricingProducts, enterpriseId, false, '', false, customerTypeValue);

    useEffect(() => {
        if (isBot || !isDynamicPriceEnabled) {
            setProductSkuPrices([]);
            setDynamicPriceLoading(false);
            return;
        }
        if ((isOptimizeScriptEnabled && isDynamicPriceEnabled) || isDynamicPricesFetched) {
            return;
        }

        const useZipcode = false;

        setDynamicPriceLoading(true);
        dynamicPriceInstance.call(useZipcode, '').then((productPrices) => {
            setProductSkuPrices(productPrices || []);
            setDynamicPriceLoading(false);
            setIsDynamicPricesFetched(true);
        }).catch(() => {
            setDynamicPriceLoading(false);
        });
    }, [
        isDynamicPriceEnabled,
        isOptimizeScriptEnabled,
        isDynamicPricesFetched,
    ]);

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: sfData?.buyAgainRecs,
        isFireImpression: false, // don't fire impression from here.
        page: { type: 'home' },
    });

    if (isMobile) {
        return (
            <>

                {
                    showBuyItAgain && (
                        <div className={`${classes.main} ${classes.buyItAgainSectionBGColor}`}>
                            <div className={classes.root}>
                                <Heading
                                    title={buyItAgainTitle || buy_again_title || 'Buy It Again'}
                                    subTitle={buyItAgainSubTitle || ''}
                                    styles={buyAgaincustomStyles}
                                />
                            </div>
                            <div className={`${classes.root}`}>
                                <MobileUIContainer
                                    products={sfData?.buyAgainRecs?.products}
                                    minRowsToShow={minimumRowsToShow || 1}
                                    trackClickThrough={trackClickThrough}
                                    containerBGColor={buyItAgainSectionBGColor}
                                    dynamicPriceInstance={dynamicPriceInstance}
                                    productSkuPrices={productSkuPrices}
                                    dynamicPriceIsLoading={dynamicPriceIsLoading}
                                />
                            </div>
                        </div>
                    )
                }
                {sfData?.buyAgainRecs?.productRecs?.length > 0 && (
                    <div className={`${classes.main} ${classes.recentlyViewedSectionBGColor}`}>
                        <div className={classes.root}>
                            <Heading
                                title={title}
                                subTitle={seedProduct?.name || subTitle}
                                styles={recentlyViewedcustomStyles}
                                product={seedProduct}
                                handleLinkOnClick={trackClickThrough}
                            />
                        </div>
                        <div className={`${classes.root}`}>
                            <MobileUIContainer
                                products={sfData?.buyAgainRecs?.productRecs}
                                minRowsToShow={minimumRowsToShow || 1}
                                trackClickThrough={trackClickThrough}
                                containerBGColor={recentlyViewedSectionBGColor}
                                dynamicPriceInstance={dynamicPriceInstance}
                                productSkuPrices={productSkuPrices}
                                dynamicPriceIsLoading={dynamicPriceIsLoading}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    }

    if (sfData?.buyAgainRecs?.productRecs?.length > 0 || showBuyItAgain) {
        return (
            <div className={`${classes.main}`}>
                <div className={classes.root}>
                    {showBuyItAgain && (
                        <Heading
                            title={buyItAgainTitle || buy_again_title || 'Buy It Again'}
                            subTitle={buyItAgainSubTitle || ''}
                            styles={buyAgaincustomStyles}
                        />
                    )}
                    {sfData?.buyAgainRecs?.productRecs?.length > 0 && (
                        <Heading
                            title={title}
                            subTitle={seedProduct?.name || subTitle}
                            styles={recentlyViewedcustomStyles}
                            product={seedProduct}
                            handleLinkOnClick={trackClickThrough}
                        />
                    )}
                </div>
                <div className={classes.root}>
                    <DesktopUIContainer
                        sfData={sfData}
                        minRowsToShow={minimumRowsToShow || 1}
                        showBuyItAgain={showBuyItAgain}
                        trackClickThrough={trackClickThrough}
                        recentlyViewedSectionBGColor={recentlyViewedSectionBGColor}
                        buyItAgainSectionBGColor={buyItAgainSectionBGColor}
                        dynamicPriceInstance={dynamicPriceInstance}
                        productSkuPrices={productSkuPrices}
                        dynamicPriceIsLoading={dynamicPriceIsLoading}
                    />
                </div>
            </div>
        );
    }

    return null;
}

BuyItAgainRecsGridUI.propTypes = {
    sfData: object,
    cmsData: object,
};

BuyItAgainRecsGridUI.defaultProps = {
    sfData: {},
    cmsData: {},
};
