/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
// libraries
import React, {
    useEffect, useState, useRef, useContext,
} from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid, Card, Typography, Fade,
} from '@material-ui/core';

import ReactHTMLParser from 'react-html-parser';
import {
    object, string, shape, bool, arrayOf, number, func, oneOfType, array,
} from 'prop-types';
import { withRouter, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { compose } from 'recompose';
import mbpLogger from 'mbp-logger';
import Media from 'react-media';
// components
import LazyLoadWrapper from '../../../GraphqlCommonComponents/LazyLoadWrapper/LazyLoadWrapper';

// helpers, redux, hooks
import { getPriceArrays } from '../../../../../helpers/DynamicPricing/getPriceArrays';
import findURL from '../../../../../gql/queries/findURL';
import findProductPageByPartNumber from '../../../../../gql/queries/findProductPageByPartNumber';
import { getFeatureFlags, getPresentationFamily } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getBrand } from '../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import {
    getSSRDeviceType, getUserSubmittedProductFilterZipcode, country, getIsBot,
} from '../../../../../../state/ducks/App/App-Selectors';
import { getActiveABTests } from '../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import determineProductUrl from '../../../../../helpers/determineProductUrl';
import { shouldRenderSnipe, snipePath, isSnipeImageActive } from '../../../GraphqlProductPage/Partials/common/commonProductPageHelper';
import { getSisterBrandName } from '../../../../../helpers/common/helper';
import PriceRange from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/PriceRange';
import SnipeRender from '../../../GraphqlProductPage/Partials/common/SnipeRender';
import getPriceFromEngine from '../../../../../../apis/product-apis/priceEngine';
import { getEnterpriseId } from '../../../../../../state/ducks/Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import { getPassportBundleCollectionData, getPassportSubscriptionStatus } from '../../../../../../state/ducks/Common/Common-Selectors';
import { withAuth } from '../../../../../../state/ducks/Member/ducks/Auth/Plugin/auth';
import { trackEvent as tracking } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { SisterBrandProductContext } from '../../../GraphqlCategoryPage/SisterBrandProducts/SisterBrandProducts';
import SnipeRenderQuery from '../../../GraphqlProductPage/Partials/common/SnipeRenderQuery';
import LockOverlay from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/LockOverlay';
import ProductName from './Partials/ProductName';
import ProductImage from './Partials/ProductImage';
import SkeletonStyles from '../../../GraphqlSkeletonComponents/SkeletonStyles';
import useVisibility from '../../../../../helpers/hooks/useVisibility';
import noop from '../../../../../helpers/noop';
import PriceBreakDownUi from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/PriceBreakDownUi';
import { getIsAuthenticatedStatus, getIsPassportUserLoggedIn } from '../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import validatePassport from '../../../../../helpers/validatePassport';
import { getPassportData } from '../../../../../../state/ducks/Passport/Passport-Selectors';
import normalizeAltCopy from '../../../../../helpers/normalizeAltCopy';

// if starting sale price equals starting retail price AND ending sale price equals
// ending retail price then display normal retail prices else we know its a sale price */}

const useStyles = makeStyles((theme) => ({
    productContentWrapperFlower: {
        paddingBottom: '0.313rem',
        '& div': {
            width: '100%',
            paddingLeft: '0.5rem !important',
            paddingRight: '0.5rem !important',
            '& p': {
                lineHeight: '1.3',
                fontSize: '0.75rem',
                [theme.breakpoints.up(769)]: {
                    lineHeight: '1.4',
                    fontSize: '1.063rem',
                },
            },
            '& $sellerName': {
                fontSize: '0.813rem',
                margin: '0',
                [theme.breakpoints.down(769)]: {
                    fontSize: '0.75rem',
                },
            },
        },
    },
    productContentWrapperFood: (styles) => ({
        padding: styles?.isSubcollectionBanner ? '0px 10px' : '',
        '& p': {
            lineHeight: '1.3',
            fontSize: '0.875rem',
            [theme.breakpoints.up(769)]: {
                lineHeight: '1.4',
                fontSize: '1rem',
            },
            [theme.breakpoints.down(768)]: {
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
            },
        },
    }),
    productPrice: {
        fontWeight: 'bold',
    },
    crossedOutRetailPrice: {
        textDecoration: 'line-through',
        fontWeight: '400',
    },
    productListingPrice: {
        color: theme.palette.product?.retailPrice || theme.palette.colorPrimary,
        fontWeight: '700',
        fontSize: '1rem',
    },
    imageCard: (styles) => ({
        width: '100%',
        paddingBottom: '105.09%', // DO NOT CHANGE pre-renders height according to img aspect ratio - only change if cdn height/width change
        marginBottom: '9px',
        borderRadius: 0,
        position: 'relative',
        background: styles?.isSubcollectionBanner ? 'none !important' : '#e3e3e3',
        boxShadow: styles?.isSubcollectionBanner ? 'none' : '0 2px 4px 0 rgba(0,0,0,0.2)',
        borderTopLeftRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
    }),
    imageCardAbTest: {
        width: '100%',
        paddingBottom: '109.09%', // DO NOT CHANGE pre-renders height according to img aspect ratio - only change if cdn height/width change
        borderRadius: 0,
        background: '#e3e3e3',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
        position: 'relative',
    },
    snipe: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '160%',
        },
        [theme.breakpoints.down(1024)]: {
            width: '100%',
        },
    },
    wineSnipe: {
        position: 'absolute',
        bottom: 0,
        top: '-5px',
        right: '-5px',
        width: '100%',
        [theme.breakpoints.down(680)]: {
            top: '-3px',
            right: '-3px',
        },
    },
    productImage: {
        display: 'block',
        width: 'inherit',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    productLink: {
        width: '100%',
        display: 'inline-block',
        textDecoration: 'none',
        color: theme.palette.cms?.black || '#000',
        borderRadius: '0.5rem 0.5rem 0 0 !important',
        '& .MuiCard-root': {
            overflow: 'visible',
        },
    },
    productLinkAbTest: {
        width: '100%',
        textDecoration: 'none',
        color: theme.palette.cms?.black || '#000',
    },
    deliveryOption: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    currentDeliveryMessageFoodBrands: {
        color: theme.palette.sympathyProductNameColor,
        fontFamily: theme.typography.fontFamily,
        textDecoration: 'none',
        fontSize: '.8em',
        fontWeight: '700',
        textTransform: 'capitalize',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    futureDeliveryMessageFoodBrands: {
        color: theme.palette.cms?.productAvailibilityPDP || '#a1001a',
        fontFamily: theme.typography.fontFamily,
        textDecoration: 'none',
        fontSize: '0.75rem',
        textTransform: 'capitalize',
        fontWeight: '700',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    abTestAvailableToShip: {
        color: '#595959',
        fontWeight: '400',
        textTransform: 'capitalize',
        fontSize: '14px',
        lineHeight: '18px',
    },
    abTestEarliestDeliveryDateFloral: {
        marginTop: '2px',
        fontSize: '0.875rem',
        lineHeight: '16px',
        color: '#595959',
        display: 'flex',
        alignItems: 'center',
        '& span': {
            color: '#000',
        },
    },
    abTestInnerProductContainerRow: {
        display: 'flex',
        flexDirection: 'row', // default but to be explicit here
    },
    abTestInnerProductContainerColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    abTestMarginRight: {
        marginRight: '16px',
    },
    abTestMarginBottom: {
        marginBottom: '13px',
    },
    abTestInnerProductFlexItem: {
        flex: '1 1 50%',
    },
    abTestAvailableToShipAndReviewContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        '& > div': {
            marginRight: '9px',
        },
    },
    noHungrykidSnipe: {
        width: '100%',
        left: 0,
    },
    OOSProductWrapper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
    },
    productMain: (styles) => ({
        height: '100%',
        flexBasis: styles?.productNotAvaliable ? '85%' : '',
        position: 'relative',
        borderColor: '#9D9D9D',
        borderRadius: '0.5rem 0.5rem 0.5rem 0.5rem',
        backgroundColor: styles?.productCardBGColor || '',
        '&:hover': {
            '& span': {
                opacity: 1,
                '& button': {
                    opacity: 0.8,
                },
                '& button:hover': {
                    opacity: 1,
                },
            },
        },
    }),
    borderApplied: {
        border: '0.5px solid var(--ENT-gray-400, #9D9D9D)',
        borderColor: '#9D9D9D',
        borderRadius: '0.5rem 0.5rem 0.5rem 0.5rem',
        height: '100%',
    },
    applyBottomRadius: {
        '& img': {
            borderRadius: '0.5rem 0.5rem 0.5rem 0.5rem !important',
        },
    },
    sisterBrandProduct: (styles) => ({
        background: styles?.sisterBrandItemBackground || 'transparent',
        padding: '21px',
        paddingTop: '0px',
        height: '100%',
        overflow: 'hidden',
        '@media screen and (min-width: 300px) and (max-width: 600px)': {
            padding: '12px',
            paddingTop: '0px',
        },
        '& .sisterBrandPrice': {
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '21px',
            letterSpacing: '0.05em',
            '@media screen and (min-width: 300px) and (max-width: 600px)': {
                lineHeight: '11px',
            },
        },
    }),
    fullHeight: {
        height: '100%',
    },
    sisterBrandProductImage: {
        boxShadow: 'none',
    },
    sisterBrandProductName: {
        fontFamily: '"Canela", Crimson, Garamond, Times, serif',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '1.5rem',
        lineHeight: '30px',
        letterSpacing: '0.01em',
        '@media screen and (min-width: 300px) and (max-width: 600px)': {
            fontSize: '1rem',
            lineHeight: '17.6px',
        },
    },
    sisterBrandName: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.75rem !important',
        lineHeight: '12px',
        letterSpacing: '0.05em',
        color: theme.palette.sisterBrandProduct?.brandNameColor || 'rgba(0, 0, 0, 0.75)',
        margin: '5px 0',
        textTransform: 'uppercase',
        '@media screen and (min-width: 300px) and (max-width: 600px)': {
            fontSize: '0.5rem !important',
            lineHeight: '6.53px',
        },
    },
    sisterBrandNameFlowers: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.875rem !important',
        lineHeight: '12px',
        letterSpacing: '0.05em',
        color: theme.palette.sisterBrandProduct?.brandNameColor || 'rgba(0, 0, 0, 0.5)',
        margin: '5px 0 15px',
        textTransform: 'capitalize',
        [theme.breakpoints.down(600)]: {
            lineHeight: '6.53px',
        },
    },
    subcollectionWine: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
    },
    badgeContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        height: '30px',
        '@media screen and (min-width: 500px)': {
            height: '45px',
        },
    },
    badgeRecs: (styles) => (
        {
            position: 'absolute',
            height: '1px',
            background: styles?.sisterBrandItemCopyColor || theme.palette.sisterBrandProduct?.tagLineSeparatorColor || '#5D8C78',
            width: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: '1',
        }
    ),
    badgeText: (styles) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '2',
        width: '77%',
        padding: '0px 2px',
        margin: '0px',
        backgroundColor: styles?.sisterBrandItemCopyBadgeText || 'white',
        color: styles?.sisterBrandItemCopyColor || theme.palette.sisterBrandProduct?.tagLineColor || '#357157',
        textAlign: 'center',
        textTransform: 'uppercase',
        '@media screen and (min-width: 300px) and (max-width: 500px)': {
            fontSize: '0.5rem',
        },
        '@media screen and (min-width: 501px) and (max-width: 850px)': {
            fontSize: '0.563rem',
        },
        '@media screen and (min-width: 851px) and (max-width: 1024px)': {
            fontSize: '0.688rem',
        },
        fontSize: '0.813rem',
    }),
    sisterBrandPrice: {
        whiteSpace: 'initial !important', // need this to overwrite the default css
    },
    sellerName: {
        margin: 0,
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        fontWeight: 'bold',
    },

    // abtest
    ...SkeletonStyles,

    // Page Elements
    productGrid: {
        display: 'grid',
        // gridTemplateColumns: 'auto auto auto',
        gridGap: '5px 20px',
        [theme.breakpoints.down(600)]: {
            // gridTemplateColumns: 'auto auto',
        },
        '&--product-block': {
            paddingBottom: '109.565217%',
        },
        '&--product-title': {
            width: '40%',
        },
        '&--product-price': {
            width: '22%',
        },
        '&--product-delivery': {
            width: '31%',
            marginBottom: '20px',
        },
    },
    sisterBrandFlowers: {
        margin: 0,
        fontSize: '0.813rem !important',
        fontWeight: 'bold',
        color: theme.palette.colorPrimary || '#65388b',
        textTransform: 'capitalize',
    },
    deliveryType: {
        lineHeight: '1.2rem',
        fontSize: '0.75rem',
        [theme.breakpoints.up(940)]: {
            lineHeight: '1.5rem',
            fontSize: '1.063rem',
        },
    },
    earliestDeliveryDateFloral: {
        color: theme.palette?.cms?.black || '#000000',
        display: 'flex',
        alignItems: 'center',
    },
    priceContainer: {
        width: '100%',
    },
    restructureCollection: {
        // marginTop: '12px',
        '& >div': {
            width: '100%',
            lineHeight: '0px',
            '& p': {
                lineHeight: '1.5rem',
                fontSize: '1rem',
                fontFamily: 'Lato',
                '@media only screen and (max-width: 768px)': {
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                },
            },
            '& $sellerName': {
                fontSize: '0.813rem',
                margin: '0',
                [theme.breakpoints.down(769)]: {
                    fontSize: '0.75rem',
                },
            },
        },
        '& [data-component="productName"]': {
            lineHeight: '1.625rem',
            fontSize: '1.125rem',
            '@media only screen and (max-width: 768px)': {
                lineHeight: '1.5rem',
                fontSize: '1rem',
            },
        },
        '& $priceContainer': {
            margin: '6px 0px 0px 0px',
            paddingLeft: 'unset !important',
            '& span': {
                lineHeight: '1.063rem',
                fontSize: '1.125rem',
                fontFamily: theme.typography.fontFamily,
                fontWeight: 700,
                letterSpacing: '0.01em',
                textAlign: 'left',
                textUnderlinePosition: 'from - font',
                textDecorationSkipInk: 'none',
                '& span > strong:first-of-type': {
                    display: 'block !important',
                    lineHeight: '1.063rem',
                    fontSize: '0.75rem',
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 400,
                    letterSpacing: '0.01em',
                    textAlign: 'left',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none',
                },
            },
        },
        '& $sisterBrandFlowers': {
            color: '#555555',
            fontSize: '1rem !important',
            fontWeight: 'normal',
            '@media only screen and (max-width: 768px)': {
                fontSize: '0.875rem !important',
            },
        },
        '& $currentDeliveryMessageFoodBrands': {
            fontWeight: 'normal',
        },
        '& $futureDeliveryMessageFoodBrands': {
            fontWeight: 'normal',
        },
        '& $abTestEarliestDeliveryDateFloral': {
            color: theme.palette.earliestDeliveryColor || '#5F3823',
        },
        '& $earliestDeliveryDateFloral': {
            color: theme.palette.earliestDeliveryColor || '#5F3823',
        },
        '& $sisterBrandName': {
            letterSpacing: 0,
            textTransform: 'none',
        },

    },
    productOptionsAvailable: {
        fontSize: '0.75rem !important',
        lineHeight: '1.063rem !important',
        color: '#555555 !important',
        fontWeight: '400',
        fontFamily: theme.typography.fontFamily,
        letterSpacing: '0.01em',
        textAlign: 'left',
    },
    dropShadow: {
        boxShadow: 'inherit !important',
    },
    wrapper: {
        marginTop: '3px !important',
    },
    priceBreakdownWrapper: {
        paddingRight: '16px',
        lineHeight: '12px',
        '@media only screen and (max-width: 768px)': {
            paddingRight: '12px',
        },
    },
    collectionPageRestructure: {
        '& $restructureCollection': {
            marginTop: '0px',
        },
        '& $productOptionsAvailable': {
            textDecoration: 'none',
            color: '#555555',
            fontSize: '12px !important',
            lineHeight: '18px !important',
            marginTop: '8px',
            '@media only screen and (max-width: 768px)': {
                lineHeight: '16px !important',
                fontSize: '12px !important',
            },
        }, // save for another ab test removing TOTAL PRICE CA
        '& $priceContainer': {
            margin: '8px 0px 0px 0px',
        },
        '& $wrapper': {
            marginTop: '8px !important',
        },
        '& [data-component="productName"]': {
            lineHeight: '24px',
            fontSize: '18px',
            '@media only screen and (max-width: 768px)': {
                lineHeight: '18px !important',
                fontSize: '14px !important',
            },
        },
        '& $sisterBrandName': {
            lineHeight: '18px !important',
            fontSize: '14px !important',
            '@media only screen and (max-width: 768px)': {
                lineHeight: '16px !important',
                fontSize: '12px !important',
            },
        },
        '& $earliestDeliveryDateFloral': {
            lineHeight: '18px !important',
            fontSize: '14px !important',
            '@media only screen and (max-width: 768px)': {
                lineHeight: '16px !important',
                fontSize: '12px !important',
            },
        },
    },
    passportEligibleText: {
        '& span': {
            fontSize: '0.75rem !important',
            lineHeight: '1.063rem !important',
            color: '#21571D !important',
            fontWeight: '400',
            fontFamily: theme.typography.fontFamily,
            letterSpacing: '0.01em',
            textAlign: 'left',
            '@media only screen and (max-width: 768px)': {
                fontSize: '12px',
                lineHeight: '18px',
            },
        },
    },
}));

export const PersonalizedHomePageProduct = ({
    name,
    partNumber,
    image,
    url,
    brand,
    skuPriceRange,
    productSkus,
    availability,
    fromCategoryPage,
    breadCrumbArray,
    productNotAvaliable,
    categoryId,
    categoryName,
    categoryLegacyId,
    skipAddons,
    showMovieUpsell,
    isPassportEligible,
    featureFlags,
    presentationFamily,
    priceRangeLayout,
    absoluteImagePath,
    activeABTests,
    ssrDeviceType,
    userSubmittedZip,
    filterOptions,
    passportBundleCollectionData,
    enterpriseId,
    isBot,
    productBrand,
    auth,
    categoryData,
    subcollectionV2Style,
    positionInCategory,
    isSubcollectionBanner,
    showSimpleProductRedesignAbTest,
    productClickCallback,
    isCategoryPage,
    productType,
    collectionPageHierarchyEnabled,
    productStyleConfig,
}) => {
    const isFoodBrand = presentationFamily === 'food';
    const sisterBrandProps = useContext(SisterBrandProductContext);
    const [visible, ref] = useVisibility(`product-item-${partNumber}`);
    const isPassportUser = useSelector(getIsPassportUserLoggedIn);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const passportData = useSelector(getPassportData);
    const isPassportMember = validatePassport('isActivePassportUser', passportData);
    const isZipDynamicPricingEnabled = featureFlags['is-zip-dynamic-pricing-enabled'];
    const isDynamicPricingEnabled = featureFlags['is-dynamic-pricing-enabled'];
    const isPassportAdded = useSelector(getPassportSubscriptionStatus);
    let customerTypeValue = [];
    const location = useLocation();
    if ((isPassportMember && isPassportUser) || isPassportAdded) {
        customerTypeValue = [{
            name: 'CUSTOMER_TYPE',
            value: 'Passport',
        }];
    } else if (isAuthenticated) {
        customerTypeValue = [{
            name: 'CUSTOMER_TYPE',
            value: 'Registered',
        }];
    }

    /** *
     *
     * Sister Brand Item treatment
     */
    const sisterBrandSameStylingFlag = categoryData?.content?.entries?.[0]?.sister_brand_s_products_same_style || false;
    const isSisterBrandAndFlagEnabled = () => {
        if (featureFlags['cross-brand-treatment'] && !sisterBrandSameStylingFlag) { // if collection enabled same style for product won't show the new style on sister product
            return getSisterBrandName(productBrand, brand.id);
        }
        return null;
    };

    // sisterBrandProps only set if flag is enabled
    let sisterBrand = null;
    const isVariant = sisterBrandProps;
    if (isVariant) {
        sisterBrand = isSisterBrandAndFlagEnabled();
    }

    const sisterBrandBackground = sisterBrand && isVariant;
    let sisterBrandItemBackground = 'transparent';
    let sisterBrandItemCopy = 'Other Gifts from us';
    let sisterBrandItemCopyColor = '#5D8C78'; // default color
    let sisterBrandItemCopyBadgeText = '';
    if (sisterBrandBackground || (isVariant && !isFoodBrand)) {
        sisterBrandItemBackground = sisterBrandProps?.item_background?.color || 'transparent';
        sisterBrandItemCopy = sisterBrandProps?.item_copy || sisterBrandItemCopy;
        sisterBrandItemCopyColor = sisterBrandProps?.item_copy_color?.color || sisterBrandItemCopyColor;
        sisterBrandItemCopyBadgeText = sisterBrandProps?.item_copy_badge_background?.color || sisterBrandItemCopyBadgeText;
    }
    const select_card_properties = productStyleConfig?.select_card_properties || [];
    const { product_card_background_color, apply_product_card_border, apply_product_card_background_color } = productStyleConfig;
    const showProductCardTitle = select_card_properties.includes('Product Title');
    const showProductCardOptions = select_card_properties.includes('Product Options');
    const showProductCardPrice = select_card_properties.includes('Price');
    const showPassportEligibleText = select_card_properties.includes('Passport Eligible');
    const showOnlyImageCard = select_card_properties.includes('Show Image Only');
    const productCardBGColor = showOnlyImageCard || !apply_product_card_background_color ? '' : product_card_background_color?.color;
    const classes = useStyles({
        sisterBrandItemBackground,
        sisterBrandItemCopyColor,
        sisterBrandItemCopyBadgeText,
        ssrDeviceType,
        productNotAvaliable,
        isSubcollectionBanner,
        productCardBGColor,
    });

    const imageContainerRef = useRef(null);
    const FIND_URL_QUERY = findURL(brand.domain, determineProductUrl(url));
    // some components pass the redux brand object to this component but others pass the brand
    // of the individual product as a string.
    const FIND_PDP_BY_PART = findProductPageByPartNumber(typeof brand === 'object' ? brand.domain : brand, partNumber);

    const [loadURL, { errorURL }] = useLazyQuery(FIND_URL_QUERY);
    const [loadPDP, { errorPDP }] = useLazyQuery(FIND_PDP_BY_PART);
    const [newPriceData, setNewPriceData] = useState(null);

    const [priceRule, setPriceRule] = useState([]);
    const [dynamicPriceData, setDynamicPriceData] = useState(null);

    // used to determine if onMouseHover of image should make calls or not
    // more info in this file near ProductQuickview component call
    const [shouldLazyLoad, setShouldLazyLoad] = useState(true);

    const priceLayout = priceRangeLayout;

    useEffect(() => {
        async function fetchPriceFromEngine() {
            if (!isDynamicPricingEnabled) {
                return;
            }

            if (!enterpriseId || !partNumber) {
                setNewPriceData(skuPriceRange);
                setPriceRule([]);
                return;
            }

            if (!isDynamicPricingEnabled && !isZipDynamicPricingEnabled) {
                setNewPriceData(skuPriceRange);
                setPriceRule([]);
                return;
            }

            const payload = {
                enterpriseId,
                products: [
                    {
                        partNumber,
                    },
                ],
                variants: customerTypeValue,
            };

            if (isZipDynamicPricingEnabled && userSubmittedZip) {
                payload.variants.push({
                    name: 'ZIP_CODE',
                    value: userSubmittedZip,
                });
            }

            getPriceFromEngine({}, null, payload, featureFlags['is-price-engine-batching-enabled'])
                .then(({ data }) => {
                    setDynamicPriceData(data?.products?.[0]);
                    const priceArrays = getPriceArrays(data?.products?.[0]?.prices);
                    if (!priceArrays?.sale?.length && !priceArrays?.retail?.length) {
                        setNewPriceData(skuPriceRange);
                        return;
                    }
                    setPriceRule(data.products?.[0]?.priceRules);
                    setNewPriceData(priceArrays);
                })
                .catch((ex) => {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        jsError: ex,
                        message: `PersonalizedHomePageProduct: fetch prices failed...${ex.message}`,
                        enterpriseId,
                        partNumber,
                    });
                    setNewPriceData(skuPriceRange);
                    setPriceRule([]);
                });
        }
        fetchPriceFromEngine();
    }, [featureFlags,
        enterpriseId,
        partNumber,
        userSubmittedZip,
    ]);

    const additionalImageArray = image?.additionalImages?.length > 0;

    if (errorURL || errorPDP) { mbpLogger.logError({ appName: process.env.npm_package_name, message: `error prefetching PDP ${errorURL} || ${errorPDP}` }); }

    const personalizationFlag = featureFlags['is-personalization-enabled'] ?? false;
    const imageLazyLoadingEnabled = featureFlags['is-simple-product-lazy-loading-enabled'];

    const isWineSnipe = image?.snipe?.includes('addwine');
    const isNkhSnipe = image?.snipe?.includes('NKH');

    const [relativeUrl, searchString] = url?.split('?');

    if (!determineProductUrl(relativeUrl) || determineProductUrl(relativeUrl)?.trim() === '/' || determineProductUrl(relativeUrl)?.trim() === '') {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'PersonalizedHomePageProduct',
            partNumber,
            productUrl: url,
            message: 'Invalid Product URL',
        });
    }

    const handleLinkOnClick = (event) => {
        if (typeof (productClickCallback) === 'function') productClickCallback();

        if (url === '/') {
            event?.preventDefault();
        }
        // The below line is to prevent a blank page when the user lands on a 404 url
        // eg /fdsfsad
        // Need to clear window.is404 before clicking on any products to prevent product unavailable page
        // but clearing window.is404 too early in the component tree leads to a blank page
        if (typeof window !== 'undefined' && window.is404) window.is404 = false;
    };

    const sendLinkTo = {
        pathname: determineProductUrl(relativeUrl),
        // Product Location state
        search: searchString ? `?${searchString}` : '',
        state: {
            prevUrl: location.pathname,
            fromCategoryPage,
            breadCrumbArray,
            categoryId,
            categoryName,
            categoryPath: location.pathname,
            categoryLegacyId,
            skipAddons,
            showMovieUpsell,
        },
    };

    const whichCollectionPageRestructure = featureFlags['which-collection-page-restructure'];
    const collectionRestructureEnabled = whichCollectionPageRestructure?.toLowerCase() !== 'control';
    const collectionRestructureVariantB = whichCollectionPageRestructure?.toLowerCase() === 'variantb';
    const collectionDropShadow = featureFlags['is-collection-drop-shadow'];

    const snipeSrc = () => {
        if (passportBundleCollectionData?.snipeImage) {
            return passportBundleCollectionData?.snipeImage;
        }
        if (image?.snipeImagePath) {
            return `${image.snipeImagePath}${image.snipe}_z.gif?auto=webp`;
        }
        return `${image.path}${image.snipe}_z.gif?auto=webp`;
    };

    const snipeHoverImagePath = `${image.path}${image?.additionalImages?.[0]}x.jpg?height=378&width=345&sharpen=a0.5,r1,t1&auto=webp`;
    const mainWineSnipe = (subcollectionV2Style && isWineSnipe) ? classes.subcollectionWine : classes.wineSnipe;

    const snipeImageRender = () => {
        if (!isFoodBrand && featureFlags['is-alt-image-hover-enabled'] && additionalImageArray && activeABTests?.alt_image_hover_enabled === 'VARIANT') {
            return (
                <img
                    src={snipeSrc()}
                    alt={normalizeAltCopy(name)}
                    className={classes.productImage}
                    onMouseOver={(e) => { e.currentTarget.src = snipeHoverImagePath; }}
                    onFocus={(e) => { e.currentTarget.src = snipeHoverImagePath; }}
                    onMouseOut={(e) => { e.currentTarget.src = snipeSrc(); }}
                    onBlur={(e) => { e.currentTarget.src = snipeSrc(); }}
                />
            );
        }
        return (
            <SnipeRender
                styleProp={`${(isWineSnipe ? mainWineSnipe : classes.snipe)} ${(isNkhSnipe ? classes.noHungrykidSnipe : null)}`}
                src={snipeSrc()}
                fallbackSrc={`${snipePath(image.path)}${image.snipe}_z.gif?auto=webp`}
            />
        );
    };
    const displayPrice = isBot || !!(!featureFlags['is-dynamic-pricing-enabled'] || (typeof window !== 'undefined' && newPriceData));
    const displayLockOverlay = false;

    /*  AB TEST ONLY
        PLEASE REMOVE IF NO LONGER NEEDED
    */
    const optimizeLoaded = activeABTests.timeout || activeABTests.loaded; // render skelly
    const whichVariantIsEnabled = activeABTests?.mobile_simple_product_redesign;
    const isMobileDevice = ssrDeviceType === 'mobile';
    const isSSR = (typeof window === 'undefined');
    const mobileSimpleProductRedesign = featureFlags['is-mobile-simple-product-redesign-enabled'] && !isBot && isMobileDevice && optimizeLoaded && whichVariantIsEnabled && showSimpleProductRedesignAbTest;

    if (featureFlags['is-mobile-simple-product-redesign-enabled'] && !isBot && isMobileDevice && (isSSR || !optimizeLoaded)) {
        return (
            <div className={classes.productGrid}>
                <div>
                    <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                    <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                    <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                    <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                </div>
            </div>
        );
    }
    const linkRender = (children, linkIndex = 0, attachTitle = false) => (
        <Link
            data-testid={`${name}${'-'}${partNumber}-${linkIndex.toString()}`?.split(' ')?.join('-')?.toLowerCase()}
            data-test={isMobileDevice ? 'mobile-personalized-hp-product' : 'desktop-personalized-hp-product'}
            title={attachTitle ? `${ReactHTMLParser(name)}` : ' '}
            tabIndex="0"
            to={sendLinkTo}
            className={mobileSimpleProductRedesign ? classes.productLinkAbTest : classes.productLink}
            onClick={handleLinkOnClick}
            replace={!!(typeof window !== 'undefined' && window.is404)}
            // Prefetching findURL and findProductPageByUrl
            onMouseOver={() => {
                // skip prefetching if no category data (on product page) prefecthing PDP queries on PDP is causing re-renders on hover
                if (!shouldLazyLoad || !categoryData?.category) return;
                loadURL();
                loadPDP();
            }}
            onFocus={() => {
                // skip prefetching if no category data (on product page) prefecthing PDP queries on PDP is causing re-renders on hover
                if (!shouldLazyLoad || !categoryData?.category) return;
                loadURL();
                loadPDP();
            }}
        >
            {children}
        </Link>
    );

    // Control
    return (
        <div data-staticid={`product-item-${partNumber}`} ref={ref} className={`${(sisterBrand && !subcollectionV2Style) ? classes.fullHeight : ''} ${productNotAvaliable ? classes.OOSProductWrapper : ''} ${ !showOnlyImageCard && apply_product_card_border ? classes.borderApplied : ''}`}>
            <div
                className={`${classes.productMain} ${(sisterBrand && !subcollectionV2Style) ? classes.sisterBrandProduct : ''} productMain`}
                key={`${brand}${partNumber}${name}`}
            >
                {linkRender(
                    <>
                        {/* Sister Brand Badging */}
                        {(sisterBrand && !subcollectionV2Style)
                            && (
                                <div className={classes.badgeContainer}>
                                    <p className={classes.badgeText}>
                                        {sisterBrandItemCopy}
                                    </p>
                                    <div className={classes.badgeRecs} />
                                </div>
                            )}
                        <Card ref={imageContainerRef} className={`${classes.imageCard} ${classes.shimmer} ${sisterBrand ? classes.sisterBrandProductImage : ''} ${collectionDropShadow ? classes.dropShadow : ''} ${!apply_product_card_background_color && (!apply_product_card_border || showOnlyImageCard) ? classes.applyBottomRadius : ''}`}>
                            <LockOverlay shouldDisplay={displayLockOverlay}>
                                {imageLazyLoadingEnabled && (
                                    <LazyLoadWrapper
                                        config={{
                                            once: true,
                                            offset: 400,
                                        }}
                                    >
                                        <Fade in timeout={700}>
                                            <ProductImage image={image} name={name} absoluteImagePath={absoluteImagePath} />
                                        </Fade>
                                    </LazyLoadWrapper>
                                )}
                                {!imageLazyLoadingEnabled
                                    && <ProductImage image={image} name={name} absoluteImagePath={absoluteImagePath} positionInCategory={positionInCategory} />}
                                {(passportBundleCollectionData?.snipeImage
                                    || shouldRenderSnipe(image?.snipe, personalizationFlag)) && isSnipeImageActive(image)
                                    ? (
                                        <LazyLoadWrapper>
                                            {imageLazyLoadingEnabled && (
                                                <Fade in timeout={700}>
                                                    {snipeImageRender()}
                                                </Fade>
                                            )}
                                            {!imageLazyLoadingEnabled
                                                && snipeImageRender()}
                                        </LazyLoadWrapper>
                                    ) : null}
                                {(categoryId || partNumber) && <SnipeRenderQuery categoryId={categoryId} partNumber={partNumber} imageContainerRef={imageContainerRef} productType="category" />}
                            </LockOverlay>
                        </Card>
                    </>, 4, true)}
                {select_card_properties.length > 0 && !showOnlyImageCard && linkRender(
                    <Grid container direction="column" className={`${isFoodBrand ? classes.productContentWrapperFood : classes.productContentWrapperFlower}  ${collectionRestructureEnabled ? classes.restructureCollection : ''}`}>
                        {showProductCardTitle && (
                            <Grid item>
                                <ProductName isFoodBrand={isFoodBrand} name={name} showSimpleProductRedesignAbTest={showSimpleProductRedesignAbTest} />
                            </Grid>
                        )}
                        {showProductCardOptions && productSkus?.length > 1 && (
                            <Grid item>
                                <Typography className={classes.productOptionsAvailable}>{`${productSkus?.length} options available`}</Typography>
                            </Grid>
                        )}
                        <Grid item>
                            <Typography className={`${isFoodBrand ? classes.productListingPrice : classes.productPrice} priceWrapper ${sisterBrand ? classes.sisterBrandPrice : classes.productPrice}`}>
                                <Media query="(min-width: 769px)" defaultMatches={ssrDeviceType === 'desktop'}>
                                    {(matches) => (
                                        <>
                                            {(showProductCardPrice && getSisterBrandName(productBrand, brand.id) && (!isVariant && !isFoodBrand) && subcollectionV2Style) ? (
                                                <div className={classes.priceContainer}>
                                                    <PriceRange
                                                        presentationFamily={presentationFamily}
                                                        priceRangeLayout={priceLayout}
                                                        skuPriceRange={featureFlags['is-dynamic-pricing-enabled'] ? newPriceData : skuPriceRange}
                                                        isMobile={!matches}
                                                        location={location}
                                                        filterOptions={filterOptions}
                                                        displayPrice={displayPrice}
                                                        sisterBrandProduct={!!(sisterBrand)}
                                                        collectionRestructureEnabled={collectionRestructureVariantB}
                                                        collectionPageHierarchyEnabled={collectionPageHierarchyEnabled}
                                                        enablePersonalizedHPProductStyle
                                                    />
                                                </div>
                                            ) : showProductCardPrice && (
                                                <div className={classes.priceContainer}>
                                                    <PriceRange
                                                        presentationFamily={presentationFamily}
                                                        priceRangeLayout={priceRangeLayout}
                                                        skuPriceRange={featureFlags['is-dynamic-pricing-enabled'] ? newPriceData : skuPriceRange}
                                                        isMobile={!matches}
                                                        location={location}
                                                        filterOptions={filterOptions}
                                                        displayPrice={displayPrice}
                                                        sisterBrandProduct={!!(sisterBrand)}
                                                        showSimpleProductRedesignAbTest={showSimpleProductRedesignAbTest}
                                                        collectionRestructureEnabled={collectionRestructureVariantB}
                                                        collectionPageHierarchyEnabled={collectionPageHierarchyEnabled}
                                                        enablePersonalizedHPProductStyle
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Media>
                            </Typography>
                        </Grid>
                        <Grid item className={`${classes.wrapper} ${isFoodBrand ? classes.productContentWrapperFood : classes.productContentWrapperFlower}`}>
                            {isPassportEligible && showPassportEligibleText && (
                                <p className={classes.passportEligibleText}>
                                    <span>Passport Eligible</span>
                                </p>
                            )}
                        </Grid>
                    </Grid>, 5,
                )}
                {showProductCardPrice && (
                    <div className={classes.priceBreakdownWrapper}>
                        <PriceBreakDownUi
                            dynamicPriceData={dynamicPriceData}
                            isCategoryPage={isCategoryPage}
                            collectionPageHierarchyEnabled={collectionPageHierarchyEnabled}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    ssrDeviceType: getSSRDeviceType(state),
    brand: getBrand(state),
    presentationFamily: getPresentationFamily(state),
    activeABTests: getActiveABTests(state),
    userSubmittedZip: getUserSubmittedProductFilterZipcode(state),
    passportBundleCollectionData: getPassportBundleCollectionData(state),
    countryName: country(state),
    enterpriseId: getEnterpriseId(state),
    isBot: getIsBot(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(tracking, dispatch),
});

PersonalizedHomePageProduct.propTypes = {
    availability: shape({
        deliveryMessage: string,
    }),
    subcollectionV2Style: bool,
    ssrDeviceType: string.isRequired,
    priceRangeLayout: arrayOf(shape({
        price_layout: string,
        sale_text: string,
        viewport: string,
    })),
    isSubcollectionBanner: bool,
    userSubmittedZip: string,
    name: string.isRequired,
    partNumber: string.isRequired,
    brand: object.isRequired,
    skuPriceRange: shape({
        sale: arrayOf(
            shape({
                value: number.isRequired,
            }),
        ),
        retail: arrayOf(
            shape({
                value: number.isRequired,
            }),
        ),
    }).isRequired,
    url: string,
    image: shape({
        name: string.isRequired,
    }),
    categoryId: string,
    categoryName: string,
    categoryLegacyId: string,
    fromCategoryPage: bool,
    breadCrumbArray: arrayOf(
        shape({
            title: string.isRequired,
            href: string.isRequired,
        }),
    ),
    skipAddons: bool,
    showMovieUpsell: oneOfType([
        bool,
        shape({
            icon: shape({
                content_type: string,
                file_size: string,
                filename: string,
                is_dir: bool,
                title: string.isRequired,
                uid: string,
                url: string.isRequired,
            }).isRequired,
            products: arrayOf(
                shape({
                    sku: string.isRequired,
                    sku_description: string.isRequired,
                    sku_price: number.isRequired,
                }).isRequired,
            ).isRequired,
        }),
    ]),
    isPassportEligible: bool,
    featureFlags: object.isRequired,
    presentationFamily: string,
    absoluteImagePath: bool,
    activeABTests: object,
    productSkus: arrayOf(
        shape({
            id: string.isRequired,
            partNumber: string.isRequired,
        }).isRequired,
    ),
    filterOptions: object,
    productNotAvaliable: bool,
    passportBundleCollectionData: shape({
        snipeImage: string,
    }),
    enterpriseId: string,
    isBot: bool.isRequired,
    productBrand: string,
    auth: shape({
        getAccessTokenSafely: func.isRequired,
    }).isRequired,
    categoryData: shape({
        content: shape({
            entries: arrayOf(shape({
                sister_brand_s_products_same_style: bool,
            })),
        }),
    }),
    positionInCategory: number,
    showSimpleProductRedesignAbTest: bool.isRequired,
    productClickCallback: func,
    isCategoryPage: bool,
    productType: string,
    collectionPageHierarchyEnabled: bool,
    productStyleConfig: array,
};

PersonalizedHomePageProduct.defaultProps = {
    availability: {
        deliveryMessage: '',
    },
    subcollectionV2Style: false,
    categoryData: {},
    productNotAvaliable: false,
    enterpriseId: '',
    fromCategoryPage: false,
    image: shape({
        altText: '',
    }),
    priceRangeLayout: [
        {
            price_layout: '',
            sale_text: '',
            viewport: 'Desktop',
        },
        {
            price_layout: '',
            sale_text: '',
            viewport: 'Mobile',
        },
    ],
    productSkus: null,
    userSubmittedZip: '',
    url: '',
    categoryId: '',
    categoryName: '',
    categoryLegacyId: '',
    skipAddons: false,
    showMovieUpsell: false,
    isPassportEligible: null,
    presentationFamily: '',
    isSubcollectionBanner: false,
    breadCrumbArray: [],
    absoluteImagePath: false,
    activeABTests: {},
    filterOptions: {},
    passportBundleCollectionData: {},
    productBrand: '',
    positionInCategory: 0,
    productClickCallback: noop,
    isCategoryPage: false,
    productType: '',
    collectionPageHierarchyEnabled: false,
    productStyleConfig: [],
};

const enhance = compose(
    withAuth,
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(PersonalizedHomePageProduct);
